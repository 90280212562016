import { ENV } from '../constants';
import { fetchWithAuth0Token } from './auth0';

const env = ENV ? ENV : 'tst';
let apiRoot;
if (env != 'prd') {
  apiRoot = `https://api.recon.data-${env}.safeguard-pay.com`;
} else {
  apiRoot = 'https://api.recon.data.safeguard-pay.com';
}

const s3presignedApiUrl = `${apiRoot}/s3presigned`;
const reconciliatorApiUrl = `${apiRoot}/reconciliation`;

export async function uploadS3(
  s3KeyFolder: string,
  fileName: string,
  data: any,
  contentType: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const finalUrl =
      s3presignedApiUrl +
      '?operation=upload' +
      '&contentType=' +
      contentType +
      '&s3KeyFolder=' +
      s3KeyFolder +
      '&fileName=' +
      fileName;

    const response = await fetchWithAuth0Token(finalUrl, auth0Token, opts);
    const responseJson = await response.json();

    const result = await fetch(responseJson.presignedURL, {
      method: 'PUT',
      body: data,
    });

    return result;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}

export async function downloadS3(
  fileName: string,
  auth0Token: string,
  opts?: any,
): Promise<any> {
  try {
    const finalUrl = `${s3presignedApiUrl}?operation=download&fileName=${fileName}`;

    const response = await fetchWithAuth0Token(finalUrl, auth0Token, opts);
    const responseJson = await response.json();

    return responseJson.presignedURL;
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}


export async function reconciliate(
  arrayFileNames: string[],
  auth0Token: string,
  phase: string,
  opts?: any,
): Promise<Response> {
  try {
    const fileParams = arrayFileNames.map((fileName) => `files=${encodeURIComponent(fileName)}`).join('&');
    const finalUrl = `${reconciliatorApiUrl}?${fileParams}&phase=${phase}`;
    return await fetchWithAuth0Token(finalUrl, auth0Token, opts);
  } catch (er: any) {
    console.log(er);
    throw er;
  }
}
