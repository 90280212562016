import { ENV } from '../constants';

const env = ENV ? ENV : 'tst';

export function getBearerTokenOpts(auth0Token: string) {
  return {
    headers: {
      Authorization: `Bearer ${auth0Token}`,
    },
  };
}

export async function fetchWithAuth0Token(
  url: string,
  auth0Token: string,
  opts: RequestInit = {},
) {
  return await fetch(
    url,
    Object.assign({}, opts, getBearerTokenOpts(auth0Token)),
  );
}

export function checkReconAuth0UserRole(user: any) {
  if (user) {
    const roles = user['https://safeguard-pay.com/roles'];
    console.log(roles);
    if (!['dev', 'qa', 'uat'].includes(env) || roles.includes('reconciliation_tool__user')) {
      console.log('VALID ROLE');
    } else {
      window.location.replace(
        'https://globalunity.safeguard-pay.com/home',
      );
    }
  } else {
    window.location.replace(
      'https://globalunity.safeguard-pay.com/home',
    );
  }
  return true;
}
