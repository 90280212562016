import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FileDropzone from './components/reconciliator/form';
import './App.css';
import { checkReconAuth0UserRole } from './utils/auth0';

export function Reconciliator() {
  document.title = 'Payroll Reconciliation';
  const { user } = useAuth0();
  const [selectedPhase, setSelectedPhase] = useState<string>('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById('phase-selector');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  checkReconAuth0UserRole(user);

  return (
    <>
      <div>
        <div className="">
          <header>
            <div className="pt-0 pb-4 px-4 sm:px-6">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                Payroll Reconciliation
              </h1>
              <p className="mt-2 max-w-8xl text-sm text-gray-700">
                Welcome to the Payroll Reconciliation tool. Upload your files according to
                the selected phase and click &apos;Start Process&apos;.
              </p>
            </div>
          </header>

          <main id="phase-selector" className="bg-gray-100 border border-gray-200 shadow rounded-lg px-4 py-0.5 sm:px-6 sm:py-1 w-[90%] max-w-7xl mx-auto">
            <FileDropzone />
          </main>

          <div className="py-2 px-4 sm:px-6 sm:py-2">
            <p className="mt-2 max-w-8xl text-sm text-gray-700">
              <strong>Note:</strong> For best results, please make sure your files
              are properly formatted and contain consistent employee data.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reconciliator;
